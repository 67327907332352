import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyDWpXpQOWdXtMjskHsLgSzEGcTPSHzIA1k",
    authDomain: "lubfoodpriv.firebaseapp.com",
    projectId: "lubfoodpriv",
    storageBucket: "lubfoodpriv.appspot.com",
    messagingSenderId: "264283712146",
    appId: "1:264283712146:web:e4a0efe6e3c5fa4a9ed44b"
}
firebase.initializeApp(firebaseConfig)

export default firebase
